.navigation-overlay {
	padding-block-end: var(--space-40--rem);
	height: 100%;
}

.navigation-overlay__header {
	display: flex;
	padding-block-start: var(--space-10--rem);
	justify-content: center;
	align-items: center;
	position: relative;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.navigation-overlay__header {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 2)) + (2 * var(--gutter)));
		justify-content: flex-start;
	}
}

.navigation-overlay__logo {
	width: 4rem;
	height: 2.5085rem;
}

.navigation-overlay__logo .icon {
	display: block;
	width: 100%;
	height: 100%;
}

/* #region Close */
.navigation-overlay__close {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	bottom: 0;
	height: 23px;
}

.navigation-overlay__close__button {
	-mrh-resets: button;
	display: flex;
	flex-direction: column;
	padding: var(--space-5--rem) var(--space-5--px);
	justify-content: space-between;
	align-items: flex-end;
	position: relative;
	width: 34px;
	height: 23px;
}

.navigation-overlay__close__line {
	background-color: var(--color-black);
	display: block;
	transform-origin: right;
	transition:
		transform 384ms ease-in-out,
		width 384ms ease-in-out;
	width: 1rem;
	height: 0.1875rem;
}

.navigation-overlay__close__line:first-child,
.navigation-overlay__close__line:last-child {
	transform: rotate(0) translateY(0) translateX(0);
}

.modal-dialog[data-state="open"] .navigation-overlay__close__line:first-child {
	transform: rotate(-26deg) translateY(-1px) translateX(-1px);
	width: 1.5rem;
}

.modal-dialog[data-state="open"] .navigation-overlay__close__line:last-child {
	transform: rotate(26deg) translateY(0.5px) translateX(-1px);
	width: 1.5rem;
}

.navigation-overlay[data-is-menus-page] .navigation-overlay__close__line:first-child {
	transform: rotate(-26deg) translateY(-1px) translateX(-1px);
	width: 1.5rem;
}

.navigation-overlay[data-is-menus-page] .navigation-overlay__close__line:last-child {
	transform: rotate(26deg) translateY(0.5px) translateX(-1px);
	width: 1.5rem;
}
/* #endregion Close */

.navigation-overlay__divider {
	margin-block: var(--space-40--rem);
}

/* #region Primary navigation */
.navigation-overlay__primary-navigation {
	list-style: none;
	padding-inline: 0;
}

.navigation-overlay__primary-navigation__item {
	text-align: center;
}

.navigation-overlay__primary-navigation__item:not(:last-child) {
	margin-block-end: var(--space-5--rem);
}

.navigation-overlay__primary-navigation__link {
	color: var(--color-black);
	text-decoration: 1px transparent underline;
	text-underline-offset: 2px;
	transition:
		color 256ms ease-in-out,
		text-decoration 256ms ease-in-out;
}

.navigation-overlay__primary-navigation__link:hover,
.navigation-overlay__primary-navigation__link:active {
	text-decoration: 1px var(--color-black) underline;
}

.navigation-overlay__primary-navigation__link:active {
	color: var(--color-brown-dark);
	text-decoration: 1px var(--color-brown-dark) underline;
}

.navigation-overlay__primary-navigation__item[data-is-current] .navigation-overlay__primary-navigation__link {
	color: var(--color-brown-dark);
	text-decoration: 1px var(--color-brown-dark) underline;
}
/* #endregion Primary navigation */

/* #region Legal + Social navigation */
.navigation-overlay__legal-navigation,
.navigation-overlay__social-navigation {
	list-style: none;
	padding-inline: 0;
}

.navigation-overlay__legal-navigation__item,
.navigation-overlay__social-navigation__item {
	text-align: center;
}

.navigation-overlay__social-navigation__item:first-child {
	margin-block-start: var(--space-10--rem);
}

.navigation-overlay__legal-navigation__item:not(:last-child),
.navigation-overlay__social-navigation__item:not(:last-child) {
	margin-block-end: var(--space-10--rem);
}
/* #endregion Legal + Social navigation */

/* #region Language switch */
.navigation-overlay__language-switch-container {
	opacity: 0;
	transition: opacity 500ms ease-in-out;
	transition-delay: 128ms;
}

.modal-dialog[data-state="open"] .navigation-overlay__language-switch-container {
	opacity: 1;
}

.navigation-overlay[data-is-menus-page] .navigation-overlay__language-switch-container {
	opacity: 1;
}

.navigation-overlay__language-switch {
	display: flex;
	margin-block: var(--space-40--rem);
	justify-content: center;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.navigation-overlay__language-switch {
		margin-block-start: var(--space-60--rem);
	}
}

.navigation-overlay__language-switch__button {
	-mrh-resets: button;
	border-top: 1px solid var(--color-black-20);
	border-bottom: 1px solid var(--color-black-20);
	padding-block: var(--space-10--rem);
	width: 100%;
}

.navigation-overlay[data-is-menus-page] .navigation-overlay__language-switch__button {
	cursor: default;
	text-align: center;
}

.navigation-overlay__language-switch__button[aria-expanded="true"] {
	border-bottom: 1px solid var(--color-black);
}

.navigation-overlay__language-switch__button .icon {
	display: inline-block;
	margin-inline-start: var(--space-5--px);
	margin-block-end: -1px;
	transform: rotate(0deg);
	transition: transform 256ms ease-out;
	width: 0.875rem;
	height: 0.875rem;
}

.navigation-overlay__language-switch__button[aria-expanded="true"] .icon {
	transform: rotate(-45deg);
}

.navigation-overlay__language-switch__menu {
	list-style: none;
	opacity: 0;
	padding: 0;
	position: absolute;
	right: 0;
	transition: opacity 128ms ease-out;
	visibility: hidden;
}

.navigation-overlay[data-is-menus-page] .navigation-overlay__language-switch__menu {
	opacity: 1;
	position: inherit;
	visibility: visible;
}

.navigation-overlay__language-switch__menu.mr-menu--expanded,
html.no-js .navigation-overlay__language-switch__menu {
	background-color: var(--color-creme);
	border: 1px solid var(--color-black-20);
	border: none;
	border-bottom: 1px solid var(--color-black);
	box-sizing: border-box;
	margin: 0;
	margin-block-start: var(--space-10--rem);
	margin-top: 2.8125rem;
	opacity: 1;
	padding-inline: 0;
	padding-block: var(--space-10--rem);
	text-align: center;
	right: var(--space-20--px);
	left: var(--space-20--px);
	visibility: visible;
}

.navigation-overlay[data-is-light-header] .navigation-overlay__language-switch__menu.mr-menu--expanded,
.navigation-overlay[data-is-light-header] html.no-js .navigation-overlay__language-switch__menu {
	background-color: var(--color-black);
	border: none;
}

.navigation-overlay__language-switch__menu__item {
	padding-inline: var(--space-20--rem);
	padding-block: var(--space-5--rem);
}
/* #endregion Language switch */
