.secondary-navigation-link {
	color: var(--color-black);
	text-decoration: 1px transparent underline;
	transition:
		color 256ms ease-in-out,
		text-decoration 256ms ease-in-out;

	&:hover {
		text-decoration: 1px var(--color-black) underline;
	}

	&:active {
		color: var(--color-brown-dark);
		text-decoration: 1px var(--color-brown-dark) underline;
		transition: none;
	}
}

.secondary-navigation-link[data-is-current] {
	color: var(--color-brown-dark);
	text-decoration: 1px var(--color-brown-dark) underline;
}
