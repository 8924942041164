.page-title {
	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		width: calc(((100% - (5 * var(--gutter))) / (6 / 5)) + ((5 - 1) * var(--gutter)));
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 8)) + ((8 - 1) * var(--gutter)));
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 6)) + ((6 - 1) * var(--gutter)));
	}
}
