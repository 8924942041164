.row {
	margin-block: var(--space-40--rem);
	position: relative;

	/* from-xsmall,min-width-560px */
	@media (width >= 35rem) {
		margin-block: var(--space-60--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-block: var(--space-80--rem);
	}
}
