.image-with-quote {
	display: flex;
	flex-direction: column;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		flex-direction: row;
		justify-content: space-between;
	}
}

.image-with-quote[data-is-quote-last] {
	flex-direction: column-reverse;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		flex-direction: row-reverse;
	}
}

.image-with-quote__quote {
	margin-block-start: 0;
	margin-block-end: var(--space-40--rem);

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		margin-block-end: var(--space-60--rem);
		width: calc(((100% - (5 * var(--gutter))) / (6 / 3)) + ((3 - 1) * var(--gutter)));
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-end: 0;
		width: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + ((3 - 1) * var(--gutter)));
	}
}

.image-with-quote[data-is-quote-last] .image-with-quote__quote {
	margin-block-start: var(--space-40--rem);
	margin-block-end: 0;

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		margin-inline-start: auto;
		margin-block-start: var(--space-60--rem);
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-block-start: 0;
	}
}

.image-with-quote__image {
	margin: 0;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 8)) + ((8 - 1) * var(--gutter)));
	}
}

.image-with-quote__image__source {
	width: 100%;
}

.image-with-quote__image__caption {
	margin-block-start: var(--space-20--rem);
}
