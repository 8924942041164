.wysiwyg {
}

.wysiwyg__title {
	margin-block-end: var(--space-40--rem);

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		margin-block-end: var(--space-60--rem);
		width: calc(((100% - (5 * var(--gutter))) / (6 / 5)) + ((5 - 1) * var(--gutter)));
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 8)) + ((8 - 1) * var(--gutter)));
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-block-end: var(--space-80--rem);
		width: calc(((100% - (11 * var(--gutter))) / (12 / 6)) + ((6 - 1) * var(--gutter)));
	}
}

.wysiwyg__columns {
	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		margin-inline-start: calc(((100% - (5 * var(--gutter))) / (6 / 1)) + (1 * var(--gutter)));
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		display: flex;
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 2)) + (2 * var(--gutter)));
		justify-content: space-between;
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 4)) + (4 * var(--gutter)));
	}
}

.wysiwyg__columns__column {
	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		flex-basis: calc((100% / 2) - (var(--gutter) / 2));
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.wysiwyg__columns__column:not(:last-child) {
	/* up-to-small,max-width-767px */
	@media (width < 48rem) {
		margin-block-end: var(--space-40--rem);
	}
}
