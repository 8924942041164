.button {
	-mrh-resets: button; /* button-reset */
	background-color: transparent;
	border: 1px solid var(--color-black);
	color: var(--color-black);
	fill: var(--color-black);
	font-family: var(--font-sans);
	font-size: 1.125rem; /* 18px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.22; /* 122.222% */
	padding-inline: var(--space-20--px);
	padding-block: var(--space-10--rem);
	text-transform: uppercase;
	transition:
		background-color 768ms,
		color 256ms,
		fill 256ms ease-in-out;
	min-height: 2.75rem;

	/* from-wide,desktop,min-width-1280px */
	@media (width >= 80rem) {
		font-size: 1.25rem; /* 20px */
		line-height: 1.2; /* 120% */
		padding-block: 0.5rem;
	}
}

/* #region Default (dark) */
.button:hover {
	background-color: var(--color-black);
	color: var(--color-white);
	fill: var(--color-white);
}

.button:active {
	background-color: var(--color-brown-dark);
	border-color: var(--color-brown-dark);
	color: var(--color-white);
	fill: var(--color-white);
	transition: none;
}
/* #endregion Default (dark) */

/* #region Light */
.button[data-variant="light"] {
	border-color: var(--color-white);
	color: var(--color-white);
	fill: var(--color-white);
}

.button[data-variant="light"]:hover {
	background-color: var(--color-white);
	color: var(--color-black);
	fill: var(--color-black);
}

.button[data-variant="light"]:active {
	color: var(--color-brown-dark);
	fill: var(--color-brown-dark);
}
/* #endregion Light */

/* #region Icon */
.button span {
	margin-inline-start: var(--space-10--px);
}

.button .icon {
	width: 0.70419rem;
	height: 0.8125rem;
}

.button[data-has-only-icon] {
	padding-inline: 0;
	width: 2.75rem;
}
/* #endregion Icon */
