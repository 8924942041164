:root {
	--theme-background-color: var(--color-creme);
	--theme-color: var(--color-black);
	--theme-footer-background-color: var(--color-white);
	--theme-footer-text-block-color: var(--color-creme);
	--theme-component-background-color: var(--color-green);
}

[data-theme-page] {
	background-color: var(--theme-background-color);
	color: var(--theme-color);
}

[data-theme-page="brown"] {
	--theme-background-color: var(--color-brown-light);
	--theme-footer-background-color: var(--color-creme);
	--theme-footer-text-block-color: var(--color-brown-light);
}

[data-theme-page="creme"] {
	--theme-background-color: var(--color-creme);
	--theme-footer-background-color: var(--color-white);
	--theme-footer-text-block-color: var(--color-creme);
}

/* #region Sub themes */
[data-theme-components="green"] {
	--theme-component-background-color: var(--color-green);
}

[data-theme-components="orange"] {
	--theme-component-background-color: var(--color-orange);
}

[data-theme-components="blue"] {
	--theme-component-background-color: var(--color-blue);
}

[data-theme-components="salmon"] {
	--theme-component-background-color: var(--color-salmon);
}

[data-theme-components="lilac"] {
	--theme-component-background-color: var(--color-lilac);
}

[data-theme-components="chestnut"] {
	--theme-component-background-color: var(--color-chestnut);
}

[data-theme-components="brown"] {
	--theme-component-background-color: var(--color-brown);
}

[data-theme-components="brown-light-shade"] {
	--theme-component-background-color: var(--color-brown-light-shade);
}
/* #endregion Sub themes */
