.hero-detail-impression {
	background-color: var(--theme-component-background-color);
	box-sizing: border-box;
	padding-block-start: calc(var(--space-40--rem) + var(--site-header-height));
	padding-block-end: var(--space-40--rem);
	height: 100vh;
	min-height: 28.125rem;
}
/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.hero-detail-impression {
		padding-block-start: calc(var(--space-60--rem) + var(--site-header-height));
		padding-block-end: var(--space-60--rem);
	}
}

.hero-detail-impression .wrapper {
	height: 100%;
}

/* #region Inner */
.hero-detail-impression__inner {
	display: grid;
	grid-row-gap: var(--space-40--rem);
	grid-template-areas: "image" "title";
	grid-template-rows: minmax(0, 1fr) minmax(0, auto);
	height: 100%;
	max-height: max-content;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.hero-detail-impression__inner {
		grid-row-gap: var(--space-60--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero-detail-impression__inner {
		grid-row-gap: 0;
		grid-column-gap: var(--gutter);
		grid-template-areas: "title image";
		grid-template-columns: minmax(0, auto) 56.73%;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hero-detail-impression__inner {
		grid-template-columns: minmax(0, auto) 56.95%;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.hero-detail-impression__inner {
		grid-template-columns: minmax(0, auto) 57.22%;
	}
}
/* #endregion Inner */

/* #region Title */
.hero-detail-impression__title {
	grid-area: title;
	margin: 0;
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero-detail-impression__title {
		align-self: center;
	}
}
/* #endregion Title */

/* #region Image */
.hero-detail-impression__image {
	grid-area: image;
}

.hero-detail-impression__image__source {
	object-fit: cover;
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
}
/* #endregion Image */
