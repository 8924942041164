.l-landing {
	background-color: var(--color-black-30);
	min-height: 100vh;
	min-height: 100svh;
}

.l-landing__header {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
}
