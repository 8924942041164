:root {
	/* #region Colors */
	--color-foreground: rgb(0 0 0);
	--color-background: rgb(246 244 239);

	/* site colors */
	--color-black-20: rgb(0 0 0 / 0.2);
	--color-black-30: rgb(0 0 0 / 0.3);
	--color-black: rgb(0 0 0);
	--color-brown-dark: rgb(81 75 70);
	--color-brown-light: rgb(231 206 191);
	--color-brown-light-shade: rgb(216 189 174);
	--color-creme: rgb(246 244 239);
	--color-white-20: rgb(255 255 255 / 0.2);
	--color-white: rgb(255 255 255);

	/* component background colors */
	--color-blue: rgb(179 213 235);
	--color-brown: rgb(232 177 146);
	--color-chestnut: rgb(212 143 134);
	--color-green: rgb(212 209 164);
	--color-lilac: rgb(227 193 208);
	--color-orange: rgb(249 168 86);
	--color-salmon: rgb(255 188 161);

	/* warnings */
	--color-grey-40: rgb(108 112 108);
	--color-warning: rgb(249 168 86); /* Orange - contrast 4.5 & 7 */
	--color-error: rgb(192 77 77); /* Red - contrast 4.5 & 7 */
	/* #endregion Colors */

	/* #region Typography */
	--font-sans: "Knockout", "Knockout Fallback", sans-serif;
	--font-serif: "Signifier", "Signifier Fallback", serif;
	/* #endregion Typography */

	/* #region Spacers */
	--space-1--px: 1px;
	--space-1--rem: 0.0625rem;
	--space-5--px: 5px;
	--space-5--rem: 0.3125rem;
	--space-10--px: 10px;
	--space-10--rem: 0.625rem;
	--space-15--px: 15px;
	--space-15--rem: 0.9375rem;
	--space-20--px: 20px;
	--space-20--rem: 1.25rem;
	--space-23--px: 23px;
	--space-23--rem: 1.4375rem;
	--space-24--px: 24px;
	--space-24--rem: 1.5rem;
	--space-28--px: 28px;
	--space-28--rem: 1.75rem;
	--space-30--px: 30px;
	--space-30--rem: 1.875rem;
	--space-32--px: 32px;
	--space-32--rem: 2rem;
	--space-36--px: 36px;
	--space-36--rem: 2.25rem;
	--space-40--px: 40px;
	--space-40--rem: 2.5rem;
	--space-60--px: 60px;
	--space-60--rem: 3.75rem;
	--space-80--px: 80px;
	--space-80--rem: 5rem;
	/* #endregion Spacers */

	/* #region Various */
	--gutter: 20px;
	--site-header-height: 3.125rem; /* 50px */
	--z-index-site-header: 90;
	--z-index-modal-dialog: 100;
	/* #endregion Various */

	/* from-375px */
	@media (width >= 23.4375rem) {
		--gutter: 23px;
	}

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		--gutter: 24px;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		--gutter: 28px;
		--site-header-height: 3.4375rem; /* 55px */
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		--gutter: 32px;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		--gutter: 36px;
	}
}
