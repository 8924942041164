.wrapper {
	box-sizing: border-box;
	padding-inline: var(--space-20--px);
	width: 100%;

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		padding-inline: var(--space-30--px);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin: 0 auto;
		padding: 0;
		max-width: 84rem;
	}
}

.wrapper[data-size="narrow"] {
	/* from-xsmall,min-width-560px */
	@media (width >= 35rem) {
		padding-inline: 112px;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		padding-inline: 146px;
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		padding-inline: 196px;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-inline: 0;
		max-width: 55.25rem;
	}
}
