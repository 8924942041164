.video {
}

.video[data-show-background-color] {
	background-color: var(--theme-component-background-color);
	padding-block: var(--space-60--rem);

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-block: var(--space-80--rem);
	}
}

.video__figure {
	margin: 0;
}

.video__figure__caption {
	margin-block-start: var(--space-20--rem);
}

/* #region Main */
.video__figure__main {
	aspect-ratio: 16 / 9;
	background-color: var(--color-black);
	position: relative;
}

.video__figure__main__player__source {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.video__figure__main__controls {
	display: flex;
	padding-inline: var(--space-15--px);
	padding-block-start: var(--space-60--rem);
	padding-block-end: var(--space-15--rem);
	justify-content: space-between;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.video__figure__main__controls::before {
	background: linear-gradient(to bottom, rgb(0 0 0 / 0) 0%, rgb(0 0 0 / 0.01) 8.1%, rgb(0 0 0 / 0.039) 15.5%, rgb(0 0 0 / 0.083) 22.5%, rgb(0 0 0 / 0.14) 29%, rgb(0 0 0 / 0.207) 35.3%, rgb(0 0 0 / 0.282) 41.2%, rgb(0 0 0 / 0.36) 47.1%, rgb(0 0 0 / 0.44) 52.9%, rgb(0 0 0 / 0.518) 58.8%, rgb(0 0 0 / 0.593) 64.7%, rgb(0 0 0 / 0.66) 71%, rgb(0 0 0 / 0.717) 77.5%, rgb(0 0 0 / 0.761) 84.5%, rgb(0 0 0 / 0.79) 91.9%, rgb(0 0 0 / 0.8) 100%);
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
}

.video__figure__main__toggle__pause {
	display: none;
}

.video__figure__main__toggle[data-playing] .video__figure__main__toggle__pause {
	display: block;
}

.video_main__toggle__play {
	display: block;
}

.video__figure__main__toggle[data-playing] .video__figure__main__toggle__play {
	display: none;
}
/* #endregion Main */
