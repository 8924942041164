.primary-navigation-link {
	color: currentColor;
	text-decoration: 1px transparent underline;
	text-underline-offset: 2px;
	transition:
		color 256ms ease-in-out,
		text-decoration 256ms ease-in-out;
}

.primary-navigation-link:hover,
.primary-navigation-link:active {
	text-decoration: 1px currentColor underline;
}

.primary-navigation-link:active {
	color: var(--color-brown-dark);
	transition: none;
}

.primary-navigation-link[data-is-current] {
	color: var(--color-brown-dark);
	text-decoration: 1px var(--color-brown-dark) underline;
}

/* #region Data Light */
.site-header[data-is-light-header] .primary-navigation-link:hover {
	color: var(--color-white);
	text-decoration: 1px var(--color-white) underline;
}

.site-header[data-is-light-header] .primary-navigation-link:active {
	color: var(--color-creme);
	text-decoration: 1px var(--color-creme) underline;
}

.site-header[data-is-light-header] .primary-navigation-link[data-is-current] {
	color: var(--color-creme);
	text-decoration: 1px var(--color-creme) underline;
}
/* #endregion Data Light */
