.hero-detail-tree {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	height: 100vh;
	height: 100svh;
	min-height: 0;
}

@supports (animation-range: 0 100vh) {
	.hero-detail-tree {
		animation: HERO_HEIGHT linear forwards;
		animation-range: 0 100vh;
		animation-timeline: scroll();
		overflow: hidden;
		position: fixed;
		top: 0;
	}
}

.hero-detail-tree[data-dark-image] {
	background-color: var(--color-black);
	color: var(--color-white);
}

.hero-detail-tree__title {
	padding-block: var(--space-80--rem);
	position: relative;
	text-align: center;
}

@supports (animation-range: 0 100vh) {
	.hero-detail-tree__title {
		animation: HERO_TITLE_FADE ease-out forwards;
		animation-range: 0 100vh;
		animation-timeline: scroll(root);
	}
}

.hero-detail-tree__background-image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.hero-detail-tree__background-image__source {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

@supports (animation-range: 0 100vh) {
	.hero-detail-tree__background-image__source {
		animation: HERO_IMAGE_SCALE ease-in-out forwards;
		animation-range: 0 100vh;
		animation-timeline: scroll(root);
	}
}

/* #region Animations */
@keyframes HERO_HEIGHT {
	0% {
		height: 100vh;
	}

	100% {
		height: 0;
	}
}

@keyframes HERO_TITLE_FADE {
	0% {
		opacity: 1;
	}

	40% {
		opacity: 1;
	}

	70% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

@keyframes HERO_IMAGE_SCALE {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.15);
	}
}
/* #endregion Animations */
