.image-50-50 {
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.image-50-50 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}

	.image-50-50[data-has-caption] {
		padding-block-end: calc(var(--space-20--rem) + 6rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-50-50[data-has-caption] {
		padding-block-end: calc(var(--space-20--rem) + 3rem);
	}
}

.image-50-50__image {
	margin: 0;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.image-50-50__image {
		position: relative;
		width: calc(50% - calc(var(--gutter) / 2));
	}
}

.image-50-50__image:not(:last-child) {
	margin-block-end: var(--space-20--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.image-50-50__image:not(:last-child) {
		margin-block-end: 0;
	}
}

.image-50-50__image__caption {
	margin-block-start: var(--space-20--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.image-50-50__image__caption {
		position: absolute;
		right: 0;
		left: 0;
	}
}

.image-50-50__image__source {
	width: 100%;
}
