.homepage-slideshow {
	position: relative;
	height: 100vh;
	height: 100svh;
	min-height: 19.8125rem;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.homepage-slideshow {
		min-height: 21.0625rem;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.homepage-slideshow {
		min-height: 23.3125rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.homepage-slideshow {
		min-height: 29.6875rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.homepage-slideshow {
		min-height: 35.1875rem;
	}
}

/* #region Slideshow */
.homepage-slideshow__media {
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.homepage-slideshow__media__item {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.homepage-slideshow__media__item {
	z-index: 1;
}

.homepage-slideshow__media__item[data-slide-item-current] {
	mask-image: linear-gradient(75deg, transparent 0%, transparent 30%, black 70%, black 100%);
	mask-position: right;
	mask-repeat: no-repeat;
	mask-size: 400%;
	z-index: 15;
}

.homepage-slideshow__media__item[data-slide-item-next] {
	transition: none;
	z-index: 10;
}

.homepage-slideshow__media__item[data-is-animating] {
	mask-position: left;
	transition: mask-position 2048ms ease-in-out;
}

.homepage-slideshow__media__item img {
	object-fit: cover;
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
}
/* #endregion Slideshow */

/* #region Content */
.homepage-slideshow__content {
	background-color: var(--color-black-30);
	box-sizing: border-box;
	color: var(--color-white);
	fill: var(--color-white);
	padding-block-start: calc(var(--space-40--rem) + var(--site-header-height));
	position: relative;
	height: 100%;
	z-index: 20;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.homepage-slideshow__content {
		padding-block-start: calc(var(--space-60--rem) + var(--site-header-height));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.homepage-slideshow__content {
		padding-block-start: calc(var(--space-80--rem) + var(--site-header-height));
	}
}

.homepage-slideshow__content__info__title {
	margin-block-end: var(--space-20--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.homepage-slideshow__content__info__title {
		width: calc(((100% - (var(--gutter) * 5)) / 6 * 5) + (var(--gutter) * 4));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.homepage-slideshow__content__info__title {
		width: calc(((100% - (var(--gutter) * 11)) / 12 * 8) + (var(--gutter) * 7));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.homepage-slideshow__content__info__title {
		width: calc(((100% - (var(--gutter) * 11)) / 12 * 6) + (var(--gutter) * 5));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.homepage-slideshow__content__info__title {
		margin-block-end: var(--space-40--rem);
	}
}

.homepage-slideshow__content__logo {
	position: absolute;
	right: var(--space-20--px);
	bottom: var(--space-20--rem);
	width: 6.25rem;
	height: 3.9375rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.homepage-slideshow__content__logo {
		width: 9.375rem;
		height: 5.875rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.homepage-slideshow__content__logo {
		right: var(--space-30--px);
		bottom: var(--space-30--rem);
		width: 12.5rem;
		height: 7.785rem;
	}
}
/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.homepage-slideshow__content__logo {
		right: var(--space-40--px);
		bottom: var(--space-40--rem);
		width: 15.625rem;
		height: 9.8125rem;
	}
}

.homepage-slideshow__content__logo .icon {
	width: 100%;
	height: 100%;
}
/* #endregion Content */
