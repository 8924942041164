.video-overlay {
	position: relative;
	height: 100%;
}

/* #region Media */
.video-overlay__media {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	padding-block: var(--space-20--rem);
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		width: calc(((100% - (5 * var(--gutter))) / (6 / 4)) + ((4 - 1) * var(--gutter)));
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 10)) + ((10 - 1) * var(--gutter)));
	}
}

.video-overlay__media__player {
	aspect-ratio: 16 / 9;
	display: block;
	margin: 0 auto;
	opacity: 0;
	position: relative;
	width: 100%;
	max-height: calc(100vh - (var(--space-20--rem) * 2));
}

.video-overlay__media__source {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
/* #endregion Media */

/* #region Button */
.video-overlay__close-button-wrapper {
	display: block;
	position: fixed;
	top: var(--space-20--rem);
	right: var(--space-20--px);

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		top: var(--space-40--rem);
		right: var(--space-40--px);
	}
}
/* #endregion Button */
