.accordion {
}

.accordion__title {
	margin-block-end: var(--space-40--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.accordion__title {
		margin-block-end: var(--space-60--rem);
		width: calc(((100% - (5 * var(--gutter))) / (6 / 5)) + ((5 - 1) * var(--gutter)));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion__title {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 8)) + ((8 - 1) * var(--gutter)));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion__title {
		margin-block-end: var(--space-80--rem);
		width: calc(((100% - (11 * var(--gutter))) / (12 / 6)) + ((6 - 1) * var(--gutter)));
	}
}

/* #region Accordion */
.accordion__block {
	box-sizing: border-box;
	display: block;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.accordion__block {
		margin-inline-start: calc(((100% - (5 * var(--gutter))) / (6 / 1)) + var(--gutter));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion__block {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 2)) + (2 * var(--gutter)));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion__block {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 4)) + (4 * var(--gutter)));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.accordion__block {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 6)) + (6 * var(--gutter)));
	}
}

.accordion__trigger {
	-mrh-resets: button;
	background-color: transparent;
	border-bottom: 1px solid var(--color-black-20);
	display: flex;
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
	justify-content: space-between;
	align-items: baseline;
	transition:
		color 384ms ease-in-out,
		border-bottom-color 384ms ease-in-out,
		background-color 384ms ease-in-out;
	width: 100%;
}

.accordion__trigger:hover {
	border-bottom: 1px solid var(--color-black);
}

.accordion__trigger:active {
	border-bottom: 1px solid var(--color-brown-dark);
	color: var(--color-brown-dark);
	transition: none;
}

.accordion__trigger[aria-expanded="true"] {
	background-color: var(--color-white);
	border-bottom: 1px solid var(--color-black-20);
	color: var(--color-brown-dark);
}

.accordion__trigger__title {
	margin-inline-end: var(--space-10--px);
	pointer-events: none;
	text-align: left;
}

/* #region Handle */
.accordion__trigger__handle {
	display: block;
	flex-shrink: 0;
	pointer-events: none;
	transform: rotate(90deg);
	transition: transform 384ms ease-in-out;
	width: 0.75rem;
	height: 0.5rem;
}

.accordion__trigger__handle .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.accordion__trigger[aria-expanded="false"] .accordion__trigger__handle {
	transform: rotate(-90deg);
}
/* #endregion Handle */

/* #region Panel */
.accordion__panel {
	border-bottom: 1px solid var(--color-brown-dark);
	padding-inline: var(--space-20--px);
	padding-block: var(--space-40--rem);
}

.accordion__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	.accordion__panel[hidden="until-found"] {
		border-bottom: none;
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}
/* #endregion Panel */
