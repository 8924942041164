.image-narrow {
}

.image-narrow[data-show-background-color] {
	background-color: var(--theme-component-background-color);
	padding-block: var(--space-60--rem);

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		padding-block: var(--space-80--rem);
	}
}
