* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-serif);
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5; /* 150% */

	/* from-wide,desktop,min-width-1280px */
	@media (width >= 80rem) {
		font-size: 1.125rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin-block: 1.5rem;
}

:is(h1, h2, h3, h4, h5, h6, p, ul, ol):first-child {
	margin-top: 0;
}

:is(h1, h2, h3, h4, h5, h6, p, ul, ol):last-child {
	margin-bottom: 0;
}

:is(h1, h2, h3, h4, h5, h6, p, ul, ol):only-child {
	margin-block: 0;
}

.type-a,
h1 {
	font-family: var(--font-sans);
	font-size: 2.5rem; /* 40px */
	font-style: normal;
	font-weight: 400;
	line-height: 1; /* 100% */
	text-transform: uppercase;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.type-a,
	h1 {
		font-size: 3.125rem; /* 50px */
	}
}

.type-b,
h2,
blockquote p {
	font-family: var(--font-sans);
	font-size: 1.875rem; /* 30px */
	font-style: normal;
	font-weight: 400;
	line-height: 1; /* 100% */
	text-transform: uppercase;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.type-b,
	h2,
	blockquote p {
		font-size: 2.5rem; /* 40px */
	}
}

.type-c,
h3 {
	font-family: var(--font-sans);
	font-size: 1.375rem; /* 22px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.09; /* 109.091% */
	text-transform: uppercase;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.type-c,
	h3 {
		font-size: 1.625rem; /* 26px */
		line-height: 1.08; /* 107.692% */
	}
}

.type-d,
h4 {
	font-family: var(--font-sans);
	font-size: 1.25rem; /* 20px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.2; /* 120% */
	text-transform: uppercase;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.type-d,
	h4 {
		font-size: 1.4375rem; /* 23px */
		line-height: 1.13; /* 113.043% */
	}
}

.type-e,
h5,
h6 {
	font-family: var(--font-sans);
	font-size: 1.125rem; /* 18px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.22; /* 122.222% */
	text-transform: uppercase;
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.type-e,
	h5,
	h6 {
		font-size: 1.25rem; /* 20px */
		line-height: 1.2; /* 120% */
	}
}

.type-f,
p {
	font-family: var(--font-serif);
	font-size: 1rem; /* 16px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.5; /* 150% */
}

/* from-wide,desktop,min-width-1280px */
@media (width >= 80rem) {
	.type-f,
	p {
		font-size: 1.125rem; /* 18px */
		line-height: 1.5; /* 150% */
	}
}

.type-g {
	font-family: var(--font-sans);
	font-size: 1.875rem; /* 30px */
	font-style: normal;
	font-weight: 400;
	line-height: 1; /* 100% */
	text-transform: uppercase;

	/* from-wide,desktop,min-width-1280px */
	@media (width >= 80rem) {
		font-size: 3.125rem; /* 50px */
	}
}

.type-h {
	font-family: var(--font-sans);
	font-size: 3.125rem; /* 50px */
	font-style: normal;
	font-weight: 400;
	line-height: 1; /* 100% */
	text-transform: uppercase;

	/* from-wide,desktop,min-width-1280px */
	@media (width >= 80rem) {
		font-size: 7.5rem;
	}
}

i,
em,
blockquote {
	font-style: italic;
}
