@media (prefers-reduced-motion: reduce) {
	*,
	*::after,
	*::before {
		animation-delay: -1ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		transition-delay: -1ms !important;
		transition-duration: 0.01ms !important;
	}
}

/*
 * Give "display: none;" for elements with a "hidden" attribute specificity [0, 2, 0].
 * This will generally be higher than layout directives : "display: flex".
 * Because this is declared very early you can easily override it with ".foo[hidden]".
 */
[hidden][hidden] {
	display: none;
}

@supports (content-visibility: hidden) and (display: revert) {
	[hidden][hidden="until-found"] {
		content-visibility: hidden;
		display: revert;
	}
}

body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	position: relative;
	text-underline-position: from-font;
	min-height: 100vh;
	min-height: 100svh;
}

body.is-showing-modal-dialog {
	overflow: hidden;
}

textarea {
	resize: none;
}

input,
button,
textarea,
select {
	font: inherit;
}

hr {
	background-color: var(--color-black-20);
	border: none;
	margin-block: var(--space-24--rem);
	height: var(--space-1--rem);
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
}

table,
th,
td {
	border: 1px solid var(--color-black-20);
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	caption-side: bottom;
	margin-inline: 0;
	margin-block: var(--space-24--rem);
	width: 100%;
	max-width: 100%;
}

table:first-child {
	margin-block-start: 0;
}

table:last-child {
	margin-block-end: 0;
}

table caption {
	font-size: 0.8125rem; /* 13px */
	margin-block-start: var(--space-10--rem);
	text-align: left;

	/* from-wide,desktop,min-width-1280px */
	@media (width >= 80rem) {
		font-size: 0.9375rem; /* 15px */
	}
}

thead {
	background-color: var(--color-white);
}

th,
td {
	box-sizing: border-box;
	padding-inline: var(--space-15--px);
	padding-block: var(--space-10--rem);
	text-align: left;
}

:is(ol, ul):not([class]) {
	box-sizing: border-box;
	margin-inline: 0;
	padding-inline-start: 1.75rem;
}

:is(ol, ul):not([class]):last-child {
	margin-block-end: 0;
}

li :is(ol, ul):not([class]) {
	margin-block-end: 0;
	padding-block-start: 0.25rem;
}

li:not([class]) {
	margin: 0;
	padding: 0 0 0.25rem 0;
	position: relative;
}

a:not([class]) {
	color: var(--color-brown-dark);
	display: inline;
	text-decoration: underline;
}

a:not([class]):hover {
	color: var(--color-black);
	transition: color 128ms ease-in-out;
}

a:not([class]):active {
	color: var(--color-brown-dark);
	transition: color 128ms ease-in-out;
}

p a:not([class]) {
	line-height: inherit;
}

.wp-block-table {
	margin: 0; /* Remove margin from Figure tag set by Gutenberg */
}

pre {
	white-space: pre-wrap;
}

blockquote {
	margin-inline: 0;
	margin-block: var(--space-24--rem);
}
