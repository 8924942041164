.pagination {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0;
	padding: 0;
	align-items: center;
}

.pagination__item {
	box-sizing: border-box;
	list-style-type: none;
	min-width: calc((44px + 44px + 2.75rem) / 3);
	min-height: calc((44px + 44px + 2.75rem) / 3);
}

.pagination__item__link,
.pagination__item[data-is-current],
.pagination__item[data-has-dots] {
	padding-inline: 1.1875rem;
	padding-block: 0.6875rem;
}

.pagination__item[data-position] .pagination__item__link {
	border: 1px solid var(--color-black);
	padding-inline: 0.9375rem;
	padding-block: 1.0625rem;
}

.pagination__item[data-is-current] {
	background-color: var(--color-brown-dark);
	color: var(--color-white);
}

.pagination__item[data-position] .icon {
	fill: currentColor;
	transform: translateX(0.0625rem);
	width: 100%;
	height: 100%;
}

.pagination__item[data-position="left"] .icon {
	transform: rotate(-180deg) translateX(0.0625rem);
}

/* #region Link */
.pagination__item__link {
	box-sizing: border-box;
	display: block;
	transition:
		border-radius 384ms ease-in-out,
		border-color 384ms ease-in-out,
		background-color 384ms ease-in-out,
		color 384ms ease-in-out;
	width: 100%;
	height: 100%;
}

.pagination__item[data-position] .icon {
	display: block;
	width: 0.6575rem;
	height: 0.48125rem;
}

.pagination__item .pagination__item__link {
	color: var(--color-black);
	text-decoration: none;
}

.pagination__item:hover .pagination__item__link {
	background-color: var(--color-black);
	color: var(--color-white);
}

.pagination__item:active .pagination__item__link {
	background-color: var(--color-brown-dark);
	border: 1px solid var(--color-brown-dark);
	color: var(--color-white);
	transition: none;
}
/* #endregion Link */
