.l-error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	text-align: center;
	width: 100%;
	min-height: 100vh;
	min-height: 100svh;
}

.l-error__container {
	padding-inline: var(--space-20--px);
	max-width: 21.5rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-error__container {
		max-width: 21.875rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.l-error__container {
		max-width: 29.125rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.l-error__container {
		max-width: 26.5rem;
	}
}

.l-error__header {
	margin-block-end: var(--space-20--rem);
}

.l-error__main p {
	margin-block-end: var(--space-20--rem);
}

.l-error__main__logo {
	display: block;
	margin: 0 auto;
	width: 6.25rem;
	height: 3.9375rem;
}

.l-error__main__logo svg {
	width: 100%;
	height: 100%;
}
