.archive-grid {
	display: grid;
	grid-row-gap: var(--gutter);
}

/* from-375px */
@media (width >= 23.4375rem) {
	.archive-grid {
		grid-row-gap: var(--space-30--rem);
		grid-column-gap: var(--gutter);
		grid-template-columns: repeat(2, 1fr);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.archive-grid {
		grid-template-columns: repeat(3, 1fr);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.archive-grid {
		grid-row-gap: var(--space-40--rem);
		grid-template-columns: repeat(4, 1fr);
	}
}

.archive-grid__item {
	background-color: transparent;
	color: var(--color-black);
	padding-inline: var(--space-30--px);
	padding-block: var(--space-30--rem);
	text-decoration: none;
	transition: background-color 384ms ease-in-out;
}

/* from-375px */
@media (width >= 23.4375rem) {
	.archive-grid__item {
		padding-inline: var(--space-20--rem);
		padding-block: var(--space-20--rem);
		align-self: flex-end;
		height: min-content;
	}
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.archive-grid__item {
		padding-inline: var(--space-30--rem);
		padding-block: var(--space-30--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.archive-grid__item {
		padding-inline: var(--space-40--rem);
		padding-block: var(--space-40--rem);
	}
}

.archive-grid__item:hover,
.archive-grid__item:active {
	background-color: var(--color-white);
}

.archive-grid__item:active {
	color: var(--color-brown-dark);
	transition: none;
}

.archive-grid__item:active .archive-grid__item__image {
	opacity: 0.85;
}

.archive-grid__item__title {
	margin-block-end: var(--space-10--rem);
}

/* #region Image */
.archive-grid__item__image {
	opacity: 1;
	overflow: hidden;
	transition: opacity 128ms ease-in-out;
}

.archive-grid__item__image__source {
	transform: scale(100%);
	transition: transform 640ms ease-in-out;
	width: 100%;
}

.archive-grid__item:hover .archive-grid__item__image__source {
	transform: scale(105%);
}
/* #endregion Image */
