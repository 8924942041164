.site-footer {
	background-color: var(--theme-footer-background-color);
	padding-block: var(--space-40--rem);

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		padding-block: var(--space-60--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		padding-block: var(--space-80--rem);
	}
}

.site-footer__header {
	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.site-footer__header__text,
.site-footer__header__block {
	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		box-sizing: border-box;
		flex-basis: calc(50% - (var(--gutter) / 2));
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.site-footer__header__text {
	/* up-to-xsmall,max-width-567px */
	@media (width < 35.5rem) {
		margin-block-end: var(--space-40--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		flex-basis: calc(((100% - (11 * var(--gutter))) / (12 / 6)) + ((6 - 1) * var(--gutter)));
	}
}

.site-footer__header__quote {
	margin-block-start: var(--space-30--rem);

	/* up-to-xsmall,max-width-567px */
	@media (width < 35.5rem) {
		margin-block-end: var(--space-40--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-block-start: var(--space-40--rem);
	}
}

.site-footer__header__block {
	background-color: var(--theme-footer-text-block-color);
	padding-inline: var(--space-30--px);
	padding-block: var(--space-30--rem);

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		flex-basis: calc(((100% - (11 * var(--gutter))) / (12 / 4)) + ((4 - 1) * var(--gutter)));
		padding-inline: var(--space-40--px);
		padding-block: var(--space-40--rem);
	}
}

.site-footer__header__block p {
	margin-block-end: var(--space-20--rem);

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-block-end: var(--space-40--rem);
	}
}

.site-footer__separator {
	margin: 0;
}

.site-footer__footer {
	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		display: flex;
		justify-content: space-between;
	}
}

.site-footer__footer__newsletter,
.site-footer__footer__menus {
	box-sizing: border-box;
	flex-basis: calc(50% - (var(--gutter) / 2));
	flex-grow: 0;
	flex-shrink: 0;

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		flex-basis: calc(((100% - (11 * var(--gutter))) / (12 / 4)) + ((4 - 1) * var(--gutter)));
	}
}

.site-footer__footer__newsletter__fields {
	position: relative;
}

.site-footer__footer__newsletter__submit {
	position: absolute;
	top: 0;
	right: 0;
}

.site-footer__footer__menus {
	display: flex;
	justify-content: space-between;

	/* up-to-xsmall,max-width-567px */
	@media (width < 35.5rem) {
		margin-block-start: var(--space-40--rem);
	}
}

.site-footer__navigation__menu {
	width: calc(50% - (var(--gutter) / 2));
}

.site-footer__navigation__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.site-footer__navigation__list__item:not(:last-child) {
	margin-block-end: var(--space-5--rem);
}
