.call-out-quote {
	background-color: var(--theme-component-background-color);
	padding-block: var(--space-40--rem);

	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		padding-block: var(--space-60--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		padding-block: var(--space-80--rem);
	}
}

.call-out-quote__inner {
	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.call-out-quote__blockquote-wrapper {
	/* from-xsmall,min-width-568px */
	@media (width >= 35.5rem) {
		width: calc(((100% - (5 * var(--gutter))) / (6 / 3)) + ((3 - 1) * var(--gutter)));
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 4)) + ((4 - 1) * var(--gutter)));
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 1)) + (1 * var(--gutter)));
		width: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + ((3 - 1) * var(--gutter)));
	}
}

.call-out-quote__blockquote {
	margin: 0;

	/* up-to-small,max-width-767px */
	@media (width < 48rem) {
		margin-block-end: var(--space-20--rem);
	}
}

.call-out-quote__blockquote p {
	margin-block-end: var(--space-20--rem);
}

.call-out-quote__image {
	display: block;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		width: calc(((100% - (11 * var(--gutter))) / (12 / 7)) + ((7 - 1) * var(--gutter)));
	}
}

.call-out-quote__image__source {
	width: 100%;
}
