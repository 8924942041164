.image-large-small {
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.image-large-small {
		display: flex;
		flex-direction: row;
		margin-block-end: calc(var(--space-20--rem) + 4.5rem);
		justify-content: space-between;
		align-items: flex-end;
	}

	.image-large-small[data-is-small-image-left] {
		flex-direction: row-reverse;
	}

	.image-large-small[data-has-caption] {
		padding-block-end: calc(var(--space-20--rem) + 6rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-large-small {
		margin-block-end: calc(var(--space-20--rem) + 3rem);
		width: calc(((100% - (11 * var(--gutter))) / (12 / 11)) + ((11 - 1) * var(--gutter)));
	}

	.image-large-small[data-is-small-image-left] {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 1)) + (1 * var(--gutter)));
	}

	.image-large-small[data-has-caption] {
		padding-block-end: calc(var(--space-20--rem) + 3rem);
	}
}

.image-large-small__image {
	margin: 0;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.image-large-small__image {
		flex-grow: 0;
		flex-shrink: 0;
		position: relative;
	}
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.image-large-small__image[data-size="large"] {
		width: calc(60.98% - calc(var(--gutter) / 2));
	}

	.image-large-small__image[data-size="small"] {
		width: calc(39.02% - calc(var(--gutter) / 2));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-large-small__image[data-size="large"] {
		width: calc(((100% - (10 * var(--gutter))) / (11 / 7)) + ((7 - 1) * var(--gutter)));
	}

	.image-large-small__image[data-size="small"] {
		width: calc(((100% - (10 * var(--gutter))) / (11 / 4)) + ((4 - 1) * var(--gutter)));
	}
}

/* up-to-xsmall,max-width-567px */
@media (width < 35.5rem) {
	.image-large-small__image:not(:last-child) {
		margin-block-end: var(--space-20--rem);
	}
}

.image-large-small__image__caption {
	margin-block-start: var(--space-20--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.image-large-small__image__caption {
		position: absolute;
	}
}

.image-large-small__image__source {
	width: 100%;
}
