.site-header {
	box-sizing: border-box;
	padding-block-start: var(--space-10--rem);
	position: relative;
	min-height: var(--site-header-height);
	z-index: var(--z-index-site-header);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.site-header {
		margin-inline-start: calc(((100% - (5 * var(--gutter))) / (6 / 1)) + (1 * var(--gutter)));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header {
		display: flex;
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 2)) + (2 * var(--gutter)));
		justify-content: space-between;
		align-items: flex-end;
	}

	.site-header[data-has-no-logo] {
		justify-content: flex-end;
	}
}

.site-header[data-is-light-header] {
	color: var(--color-white);
	fill: var(--color-white);
}

.site-header__logo {
	display: block;
	margin: 0 auto;
	width: 4rem;
	height: 2.5085rem;
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.site-header__logo {
		margin: 0;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__logo {
		margin-inline-start: 0;
		margin-block-end: var(--space-5--rem);
	}
}

.site-header__logo .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.site-header[data-is-light-header] .site-header__logo .icon {
	fill: var(--color-white);
}

/* #region Mobile */
.site-header__mobile {
	position: absolute;
	right: 0;
	bottom: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__mobile {
		display: none;
	}
}

.site-header__mobile__hamburger {
	-mrh-resets: button;
	display: flex;
	flex-direction: column;
	padding-inline: var(--space-5--px);
	padding-block: var(--space-5--rem);
	align-items: flex-end;
}

.site-header__mobile__hamburger:active .site-header__mobile__hamburger__line {
	background-color: var(--color-brown-dark);
}

.site-header__mobile__hamburger__line {
	background-color: currentColor;
	display: block;
	transform-origin: right;
	transition:
		transform 384ms ease-in-out,
		width 384ms ease-in-out;
	transition-delay: 128ms;
	height: 0.1875rem;
}

.site-header__mobile__hamburger__line[data-size="small"] {
	width: 1rem;
}

.site-header__mobile__hamburger__line[data-size="large"] {
	width: 1.5rem;
}

.site-header__mobile__hamburger__line:first-child,
.site-header__mobile__hamburger__line:last-child {
	transform: rotate(0) translateY(0) translateX(0);
}

body.is-showing-modal-dialog .site-header__mobile__hamburger__line:first-child {
	transform: rotate(-26deg) translateY(-1px) translateX(-1px);
	width: 1.5rem;
}

body.is-showing-modal-dialog .site-header__mobile__hamburger__line[data-size="large"] {
	width: 0;
}

body.is-showing-modal-dialog .site-header__mobile__hamburger__line:last-child {
	transform: rotate(26deg) translateY(0.5px) translateX(-1px);
	width: 1.5rem;
}

.site-header__mobile__hamburger__line:not(:last-child) {
	margin-block-end: 0.125rem;
}
/* #endregion Mobile */

/* #region Desktop */
.site-header__desktop {
	display: none;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__desktop {
		display: flex;
	}
}

.site-header__navigation__list {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-header__navigation__list__item:not(:last-child) {
	margin-inline-end: var(--space-20--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header__navigation__list__item:not(:last-child) {
		margin-inline-end: var(--space-40--rem);
	}
}
/* #endregion Desktop */

/* #region Language */
/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-header__language-switch {
		display: block;
		margin-inline-start: var(--space-20--rem);
		align-self: center;
		position: relative;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header__language-switch {
		margin-inline-start: var(--space-40--rem);
	}
}

.site-header__language-switch__button {
	-mrh-resets: button;
}

.site-header[data-is-light-header] .site-header__language-switch__button {
	color: var(--color-white);
}

.site-header__language-switch__button .icon {
	display: inline-block;
	margin-inline-start: var(--space-5--px);
	transform: rotate(0deg);
	transition: transform 256ms ease-out;
	width: 0.875rem;
	height: 0.875rem;
}

.site-header__language-switch__button[aria-expanded="true"] .icon {
	transform: rotate(-45deg);
}

.site-header__language-switch__menu {
	list-style: none;
	opacity: 0;
	position: absolute;
	right: 0;
	transition: opacity 128ms ease-out;
	visibility: hidden;
}

.site-header__language-switch__menu.mr-menu--expanded,
html.no-js .site-header__language-switch__menu {
	background-color: var(--color-creme);
	border: 1px solid var(--color-black-20);
	margin-block-start: var(--space-10--rem);
	opacity: 1;
	padding-inline: 0;
	padding-block: var(--space-10--rem);
	visibility: visible;
	width: 9.375rem;
}

.site-header[data-is-light-header] .site-header__language-switch__menu.mr-menu--expanded,
.site-header[data-is-light-header] html.no-js .site-header__language-switch__menu {
	background-color: var(--color-black);
	border: none;
}

.site-header__language-switch__menu__item {
	padding-inline: var(--space-20--rem);
	padding-block: var(--space-5--rem);
}
/* #endregion Language */

/* #region Styleguide */
.l-styleguide .site-header[data-is-light-header] {
	background-color: var(--color-black);
}
/* #endregion Styleguide */
