.description-list {
}

.description-list__title {
	margin-block-end: var(--space-40--rem);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.description-list__title {
		margin-inline-end: calc(((100% - (5 * var(--gutter))) / (6 / 1)) + (1 * var(--gutter)));
		margin-block-end: var(--space-60--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.description-list__title {
		margin-inline-end: calc(((100% - (11 * var(--gutter))) / (12 / 4)) + (4 * var(--gutter)));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.description-list__title {
		margin-inline-end: calc(((100% - (11 * var(--gutter))) / (12 / 6)) + (6 * var(--gutter)));
		margin-block-end: var(--space-80--rem);
	}
}

.description-list__items {
	margin-block: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.description-list__items {
		margin-inline-start: calc(((100% - (5 * var(--gutter))) / (6 / 1)) + (1 * var(--gutter)));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.description-list__items {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 2)) + (2 * var(--gutter)));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.description-list__items {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 4)) + (4 * var(--gutter)));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.description-list__items {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 6)) + (6 * var(--gutter)));
	}
}

.description-list__item {
	border-top: 1px solid var(--color-black-20);
	padding-block: var(--space-20--rem);
}

.description-list__item:last-child {
	border-bottom: 1px solid var(--color-black-20);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.description-list__item {
		display: flex;
		justify-content: space-between;
	}
}

.description-list__item__title {
	margin-block-end: var(--space-10--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.description-list__item__title {
		margin-block: 0;
		width: calc(((100% - (9 * var(--gutter))) / (10 / 3)) + ((3 - 1) * var(--gutter)));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.description-list__item__title {
		width: calc(((100% - (7 * var(--gutter))) / (8 / 2)) + ((2 - 1) * var(--gutter)));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.description-list__item__title {
		width: calc(((100% - (5 * var(--gutter))) / (6 / 2)) + ((2 - 1) * var(--gutter)));
	}
}

.description-list__item__title,
.description-list__item__text {
	margin-inline: 0;
}

.description-list__item__text {
	display: flex;
	flex-direction: column;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.description-list__item__text {
		width: calc(((100% - (9 * var(--gutter))) / (10 / 7)) + ((7 - 1) * var(--gutter)));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.description-list__item__text {
		width: calc(((100% - (7 * var(--gutter))) / (8 / 6)) + ((6 - 1) * var(--gutter)));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.description-list__item__text {
		width: calc(((100% - (5 * var(--gutter))) / (6 / 4)) + ((4 - 1) * var(--gutter)));
	}
}

.description-list__item__text p {
	margin: 0;
}

.description-list__item__text span {
	color: var(--color-brown-dark);
}
