.image {
	margin: 0;
}

.image__source {
	width: 100%;
}

.image__caption {
	margin-block-start: var(--space-20--rem);
}
