.recommended {
	border-top: 1px solid var(--color-black-20);
	padding-block-start: var(--space-40--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.recommended {
		padding-block-start: var(--space-60--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.recommended {
		padding-block-start: var(--space-80--rem);
	}
}

/* up-to-small,max-width-767px */
@media (width < 48rem) {
	.recommended .archive-grid .archive-grid__item:nth-child(n + 3) {
		display: none;
	}
}

/* up-to-large,max-width-1439px */
@media (width < 90rem) {
	.recommended .archive-grid .archive-grid__item:nth-child(n + 4) {
		display: none;
	}
}

.recommended__title {
	margin-block-end: var(--space-40--rem);
}

/* from-xsmall,min-width-568px */
@media (width >= 35.5rem) {
	.recommended__title {
		margin-block-end: var(--space-60--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.recommended__title {
		margin-block-end: var(--space-80--rem);
	}
}
