.l-single-tree {
}

@supports (animation-range: 0 100vh) {
	.l-single-tree {
		padding-top: calc(100vh);
		padding-top: calc(100svh);
	}
}

.l-single-tree__header {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
}
